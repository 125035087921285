import { ref } from "vue";
import { useRouter } from "vue-router";
import { pageorders } from "@/api/userInfo";
import { showLoading, hideLoading } from "@/utils/utils"; // 引入封装的加载状态工具

export default {
  name: "quotaHistory",

  setup() {
    const router = useRouter();
    const selectedOption = ref("");
    const dropdownOptions = [{
      text: "All",
      value: ""
    }, {
      text: "Platform",
      value: "0"
    }, {
      text: "TRC20",
      value: "1"
    }, {
      text: "Bank Account",
      value: "2"
    }, {
      text: "INR",
      value: "inr"
    }, {
      text: "USDT",
      value: "usdt"
    }];
    const loading = ref(false);
    const finished = ref(false);
    const items = ref([]);
    const detailsDialogVisible = ref(false);
    const currentDetail = ref({
      orderId: "",
      amount: 0,
      commission: 0,
      createDate: "",
      finishDate: "",
      currency: "",
      addrType: "",
      inAddr: "",
      sendAddr: "",
      rate: 0,
      orderAmount: 0,
      type: ""
    });

    const onClickLeft = () => history.back(); // 根据订单状态获取颜色


    const getStatusColor = status => {
      switch (status) {
        case "0":
          return "orange";
        // 等待交易

        case "1":
          return "blue";
        // 进行中

        case "2":
          return "red";
        // 交易失败

        case "3":
          return "green";
        // 交易完成

        default:
          return "gray";
      }
    }; // 获取订单状态的描述


    const getStatusDescription = status => {
      const descriptions = {
        "0": "Pending",
        "1": "In Progress",
        "2": "Failed",
        "3": "Completed"
      };
      return descriptions[status] || "Unknown";
    }; // 获取订单类型描述


    const getTypeDescription = type => {
      const types = {
        "0": "USDT充值平台卢比",
        "1": "卢比提现USDT",
        "2": "卢比平台交易",
        "3": "卢比提现银行",
        "4": "用户订单"
      };
      return types[type] || "Unknown";
    }; // 展示订单详情


    const showDetails = item => {
      currentDetail.value = { ...item
      };
      detailsDialogVisible.value = true;
    }; // 关闭弹窗


    const closeDialog = () => {
      detailsDialogVisible.value = false;
    }; // 加载更多数据


    const onLoad = async () => {
      showLoading("Loading...");
      loading.value = true;

      try {
        const response = await pageorders({
          addrType: selectedOption.value
        });

        if (response.code === 1) {
          items.value.push(...response.data.records);
          if (items.value.length >= response.data.total) finished.value = true;
        }
      } catch (error) {
        console.error("加载数据失败", error);
      }

      hideLoading();
      loading.value = false;
    }; // 当下拉菜单选项改变时重新加载数据


    const onDropdownChange = async () => {
      items.value = [];
      finished.value = false;
      await onLoad();
    };

    return {
      onClickLeft,
      selectedOption,
      dropdownOptions,
      onLoad,
      onDropdownChange,
      showDetails,
      closeDialog,
      detailsDialogVisible,
      currentDetail,
      items,
      loading,
      finished,
      getStatusColor,
      getStatusDescription,
      getTypeDescription
    };
  }

};